<template>
  <div class="dashboard-content">
    <div class="row">
       <div class="col-lg-12 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
            <h6 class="mb-2">
            Add Location
            </h6>
            <hr>
            <div class="row container" >
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <form ref="child_form">
                        <div class="form-group row">
                            <label for="street_address" class="col-sm-3 col-form-label">Street Address</label>
                            <div class="col-sm-9">
                            <input type="text" v-model="obj.street" class="form-control" id="street_address" placeholder="Street Address" >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="zip_code" class="col-sm-3 col-form-label">ZIP Code</label>
                            <div class="col-sm-9">
                            <input v-model="obj.zipcode" type="number" class="form-control" id="zip_code" placeholder="Enter ZIP Code">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="link" class="col-sm-3 col-form-label">Unique Link <span class="text-danger font-weight-bold"> (optional)</span></label>
                            <div class="col-sm-9">
                            <input v-model="obj.link" type="text" class="form-control" id="link" placeholder="https://">
                            </div>
                        </div>
                        </form>
                </div>
                <div class="col-md-2"></div>
            </div>
              <hr>   
                <div class="row container ">
                    <h6 class="mb-2">Local User Access </h6>
                </div>
               <span class="text-danger font-weight-bold"> (optional)</span>
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <form>
                               <div class="form-group row">
                                    <label for="email" class="col-sm-3 col-form-label">Name</label>
                                    <div class="col-sm-9">
                                    <input v-model="obj.name" type="text" class="form-control"  name="child_name" placeholder="Child Name">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="email" class="col-sm-3 col-form-label">Email</label>
                                    <div class="col-sm-9">
                                    <input v-model="obj.email" type="email" class="form-control" name="child_email"  placeholder="Email">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="password" class="col-sm-3 col-form-label">Password</label>
                                    <div class="col-sm-9">
                                    <input v-model="obj.password" type="password" autocomplete="new-password" class="form-control" name="password" data-name="password" placeholder="Enter Password" >
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-2"></div>
                  </div>
                  <div class="row ">
                    <div class="col-md-12  text-center">
                       <button v-on:click="createLocation" class="btn btn-success text-center"> Add Location </button>
                    </div>

                  </div>
              </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="copyrights">
          © 2021 Thriggle. All Rights Reserved.
        </div>
      </div>
    </div>
</template>

<script>
import {axios} from '../../../services/http-common';
export default {
  data(){
    return{
      obj:{
        name:'',
        email:'',
        link:'',
        zipcode:'',
        street:'',
        password:'',
      }
    }
  },
  methods:{
    createLocation(){
       return axios
            .post(`${process.env.VUE_APP_API_URL}create-child`,this.obj)
            .then(response => {
               this.obj.name='',
               this.obj.email='',
               this.obj.link='',
               this.obj.zipcode='',
               this.obj.street='',
               this.obj.password='',

               this.$toast.success('Location Added', {
                  position: "bottom-right",
                  timeout: 2508,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                  top:'300px',
                });
                
         }).catch(error => {
            this.$toast.error('Something Went Wrong!', {
                  position: "bottom-right",
                  timeout: 2508,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                  top:'300px',
                });
         });
    }
  }
}
</script>